<template>
  <div
    class="d-flex align-items-center border p-3 mt-4"
    :class="'border-' + cStatus.color"
  >
    <div class="icone mr-3">
      <i class="icon-3x" :class="`text-${cStatus.color} ${item.icon}`"></i>
    </div>
    <div class="box-text flex-grow-1">
      <h4 class="mb-0">{{ item.titulo }}</h4>
    </div>
    <div class="button">
      <b-button
        :variant="cButton.color"
        :disabled="cButton.disabled"
        :to="item.to"
      >
        {{ cButton.text }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OPSection",
  props: {
    item: Object
  },
  computed: {
    cStatus() {
      let options = [
        {
          // nao cadastrado: 0
          icon: "la la-clock",
          color: "light-dark"
        },
        {
          // cadastrado: 1
          icon: "la la-check",
          color: "success"
        },
        {
          // erro: 2
          icon: "la la-times",
          color: "danger"
        }
      ];

      return options[this.item.status ?? 0];
    },
    cButton() {
      return this.item.isBlocked
        ? {
            text: "Bloqueado",
            color: "secondary",
            disabled: true
          }
        : {
            text: "Abrir",
            color: "primary",
            disabled: false
          };
    }
  }
};
</script>
