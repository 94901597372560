<template>
  <div>
    <KTCard :page="page_risco" class="mb-8">
      <template #body>
        <div class="d-flex">
          <div
            class="
              d-flex
              align-items-center
              justify-content-center
              py-3
              px-4
              border
            "
            v-for="(tipo, index) in c_tipos"
            :key="'tipos-' + index"
            :class="{ 'bg-success text-white': tipo.selected }"
          >
            {{ tipo.risco }}
          </div>
        </div>
      </template>
    </KTCard>
    <KTCard :page="page">
      <template #body>
        <template v-if="!concluido">
          <template v-if="viavel">
            <p>
              Se alguma das versões apresentou resultado Inviável recomendamos
              que você identifique e revise os pontos considerados mais críticos
              do projeto e da análise econômico-financeira.
            </p>
            <p>
              Mesmo que o resultado da duas versões se apresente como Viável,
              verifique se os indicadores estão suficientemente atrativos.
              Recomendamos que sua decisão sobre investir no projeto seja tomada
              com base no resultado que adota a maior TMA.
            </p>
            <p>É possível Revisar seu plano plano clicando no botão abaixo:</p>
          </template>
          <template v-else>
            <p>
              O seu plano foi considerado Inviável. Neste caso recomendamos que
              você identifique e revise os pontos considerados mais críticos do
              projeto e da análise econômico-financeira:
            </p>
          </template>
          <div class="mb-6">
            <b-button variant="warning" size="lg" @click="onSubmitRevisar">
              Revisar
            </b-button>
          </div>
        </template>
        <p v-else>
          Este é o resultado da sua avaliação de risco preenchida anteriormente.
          Confira abaixo qual classificação o sistema indicou para o seu plano.
        </p>

        <KTTable
          :fields="resultado.columns"
          :items="resultado.data"
          :primaryKey="resultado.primaryKey"
          :sortBy="resultado.sortBy"
          :paginated="false"
          :searchable="false"
        >
          <template #custom-foot v-if="!concluido">
            <tr class="bg-secondary">
              <td class="align-middle"><b>Conclusão</b></td>
              <td></td>
              <td class="text-right">
                <b-button
                  @click="onSubmitSituacaoUsuario"
                  variant="success"
                  size="lg"
                  class="mr-2"
                  :disabled="ApiIsLoading"
                >
                  Escolher essa
                </b-button>
              </td>
              <td class="text-right">
                <b-button
                  @click="onSubmitSituacaoSistema"
                  variant="success"
                  size="lg"
                  :disabled="ApiIsLoading"
                >
                  Escolher essa
                </b-button>
              </td>
            </tr>
          </template>
        </KTTable>
      </template>
      <template #footer>
        <div class="d-flex justify-content-between" v-if="concluido">
          <b-button variant="primary" size="lg" @click="download"
            >Gerar Word</b-button
          >
          <div class="text-right">
            Data de conclusão: {{ data_cadastro_situacao }}
          </div>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import MsgService from "@/core/services/msg.service";

export default {
  name: "PipResultadoList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page_risco: {
        icon: "la la-tasks",
        title: "Potencial de risco determinado pelo Sistema",
        description: `Este é o resultado da sua avaliação de risco preenchida anteriormente.
          Confira abaixo qual classificação o sistema indicou para o seu plano.`
      },
      page: {
        icon: "la la-tachometer-alt",
        title:
          "Resultado da Análise dos Indicadores de Viabilidade Econômica e Financeira"
      },
      resultado_options: {
        data: [],
        primaryKey: null,
        sortBy: ""
      },
      viavel: null,
      risco: null,
      tipos_risco: ["Muito baixo", "Baixo", "Neutro", "Alto", "Muito alto"],
      concluido: null,
      data_cadastro_situacao: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/resultado`;
    },
    manual() {
      return {
        session: "resultado",
        tipo: this.tipo_plano_investimento
      };
    },
    c_tipos() {
      return this.tipos_risco.map((value, index) => ({
        risco: value,
        selected: this.risco >= index + 1 && this.risco < index + 2
      }));
    },
    resultado() {
      let columns = [
        {
          key: "descricao",
          label: "Descrição",
          sortable: false
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade",
          sortable: false,
          tdClass: "text-center"
        }
      ];
      if (this.concluido) {
        columns.push({
          key: "valor",
          label: "Situação",
          sortable: false,
          tdClass: "text-right",
          formatter: "currency"
        });
      } else {
        columns.push({
          key: "valor",
          label: "Situação Criada pelo Usuário",
          sortable: false,
          tdClass: "text-right",
          formatter: "currency"
        });
        columns.push({
          key: "valor_ajustado",
          label: "Situação Criada pelo Sistema",
          sortable: false,
          tdClass: "text-right",
          formatter: "currency"
        });
      }
      return {
        ...this.resultado_options,
        columns
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.resultado_options.data = res[R_GETLIST].resultado;
        this.viavel = res[R_GETLIST].viavel;
        this.risco = res[R_GETLIST].risco;
        this.concluido = res[R_GETLIST].concluido;
        this.data_cadastro_situacao = res[R_GETLIST].data_cadastro_situacao;
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    async onSubmitRevisar() {
      const result = await MsgService.confirm(
        {
          title: "Confirmação de reabertura".toUpperCase(),
          text: "Tem certeza que deseja prosseguir com a reabertura do plano para revisar?"
        },
        {
          showConfirmFeedback: false,
          showDenyFeedback: false
        }
      );

      if (result.isConfirmed) {
        this.ApiSubmitUpdate("revisar", {}, "refresh");
      }
    },
    async onSubmitSituacaoUsuario() {
      await this.onSubmitSituacao(1);
    },
    async onSubmitSituacaoSistema() {
      await this.onSubmitSituacao(2);
    },
    async onSubmitSituacao(situacao) {
      const result = await MsgService.confirm(
        {
          title: "Confirmação de conclusão".toUpperCase(),
          text: "Tem certeza que deseja prosseguir com a conclusão do plano? Após confirmar não será mais possível revisar algo."
        },
        {
          showConfirmFeedback: false,
          showDenyFeedback: false
        }
      );

      if (result.isConfirmed) {
        this.ApiSubmitAdd({ situacao }, "refresh");
      }
    },
    download() {
      this.ApiGetFile("download");
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
