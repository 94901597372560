<template>
  <div>
    <KTCard :page="page" :class="{ 'mb-8': finalizado }">
      <template #body>
        <div class="accordion" role="tablist" v-if="finalizado">
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-3 d-flex justify-content-between align-items-center"
              role="tab"
              :style="{ cursor: 'auto' }"
            >
              <b>Seções do {{ descricao_tipo }}</b>
              <b-button v-b-toggle.accordion-1 variant="secondary" size="sm">
                <span v-if="!accordion">
                  <i class="fa fa-angle-down"></i> Abrir
                </span>
                <span v-else> <i class="fa fa-angle-up"></i> Fechar </span>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              accordion="my-accordion"
              role="tabpanel"
              v-model="accordion"
              class="px-4 pb-4"
            >
              <OPSection
                v-for="(s, key) in sections_tipo"
                :item="s"
                :key="key"
              ></OPSection>
            </b-collapse>
          </b-card>
        </div>
        <OPSection
          v-else
          v-for="(s, key) in sections_tipo"
          :item="s"
          :key="key"
        ></OPSection>
      </template>
      <template #footer>
        <div class="text-right">
          <b-button
            type="submit"
            variant="success"
            size="lg"
            @click="onSubmit"
            :disabled="!canFinish"
            v-if="!finalizado"
          >
            Finalizar
          </b-button>
          <p v-if="finalizado" class="mb-0">
            Data de finalização: {{ data_finalizado }}.
          </p>
        </div>
      </template>
    </KTCard>
    <ResultadoList
      :idplano_investimento="idplano_investimento"
      v-if="finalizado"
    />
  </div>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import MsgService from "@/core/services/msg.service";
import OPSection from "@/view/content/onepage/OPSection.vue";
import ResultadoList from "@/view/pages/pip/resultado/PipResultadoList.vue";

export default {
  name: "PipOnepage",
  mixins: [ApiMixin, HelpersMixin],
  components: {
    OPSection,
    ResultadoList
  },
  props: {
    idplano_investimento: {
      required: true
    }
  },
  data() {
    return {
      apiResource: "pip",
      parentRoute: { name: "inicio" },
      form: {},
      finalizado: false,
      data_finalizado: "",
      bloqueio: {},
      status: {},
      showSections: false,
      accordion: false
    };
  },
  computed: {
    tipo() {
      return this.form.tipo_plano_investimento == 1 ? "PP" : "PN";
    },
    descricao_tipo() {
      return this.form.tipo_plano_investimento == 1
        ? "Plano de Investimento Rural"
        : "Plano de Negócios";
    },
    page() {
      return {
        title: this.form.titulo,
        description:
          this.form.tipo_plano_investimento == 1
            ? `Para iniciar a análise econômico-financeira do seu Plano
          de Investimento Rural é necessário seguir a sequência de
          etapas definida pela PAEF. Observe que à medida que as
          primeiras etapas são concluídas, outras são liberadas, até
          chegar ao final. O objetivo desta metodologia é possibilitar
          que você tenha o foco necessário para refletir e incluir
          informações consistentes em cada tópico. Bom trabalho!`
            : `Para iniciar a análise econômico-financeira do seu Plano
          de Negócios é necessário seguir a sequência de etapas definida
          pela PAEF. Observe que à medida que as primeiras etapas são
          concluídas, outras são liberadas, até chegar ao final. O
          objetivo desta metodologia é possibilitar que você tenha o
          foco necessário para refletir e incluir informações consistentes
          em cada tópico. Bom trabalho!`
      };
    },
    canFinish() {
      return this.bloqueio.finalizacao == "t";
    },
    sections_tipo() {
      let sections = {
        PP: {
          dados_gerais: {
            titulo: "Dados Gerais",
            icon: "la la-file-alt",
            isBlocked: this.bloqueio.dados_gerais != "t",
            status: this.status.dados_gerais,
            to: {
              name: "pip_dados_gerais_form",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          produtos: {
            titulo: "Produtos",
            icon: "la la-apple-alt",
            isBlocked: this.bloqueio.produto != "t",
            status: this.status.produto,
            to: {
              name: "pip_produto_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          potencial_risco: {
            titulo: "Avaliação de Risco",
            icon: "la la-tasks",
            isBlocked: this.bloqueio.potencial_risco != "t",
            status: this.status.potencial_risco,
            to: {
              name: "pip_potencial_risco_form",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          investimentos: {
            titulo: "Investimento Total",
            icon: "la la-dollar-sign",
            isBlocked: this.bloqueio.investimento != "t",
            status: this.status.investimento,
            to: {
              name: "pip_investimento_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          depreciacao: {
            titulo: "Depreciação",
            icon: "la la-dollar-sign",
            isBlocked: this.bloqueio.investimento_depreciacao != "t",
            status: this.status.investimento_depreciacao,
            to: {
              name: "pip_depreciacao_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          resumo_investimento: {
            titulo: "Resumo dos Investimentos",
            icon: "la la-dollar-sign",
            isBlocked: this.bloqueio.resumo_investimento != "t",
            status: this.status.resumo_investimento,
            to: {
              name: "pip_resumo_investimento_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          plano_vendas: {
            titulo: "Plano de Vendas",
            icon: "la la-calendar",
            isBlocked: this.bloqueio.plano_vendas != "t",
            status: this.status.plano_vendas,
            to: {
              name: "pip_plano_vendas_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          preco_vendas: {
            titulo: "Preço de Venda",
            icon: "la la-money-bill-wave",
            isBlocked: this.bloqueio.preco_venda != "t",
            status: this.status.preco_venda,
            to: {
              name: "pip_preco_vendas_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          receita: {
            titulo: "Receitas",
            icon: "la la-thumbs-up",
            isBlocked: this.bloqueio.receita != "t",
            status: this.status.receita,
            to: {
              name: "pip_receita_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          autoconsumo: {
            titulo: "Autoconsumo",
            icon: "la la-user-circle",
            isBlocked: this.bloqueio.autoconsumo != "t",
            status: this.status.autoconsumo,
            to: {
              name: "pip_autoconsumo_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          despesa_operacional: {
            titulo: "Despesas Operacionais",
            descricao:
              "Despesas Operacionais previstas com a implantação Projeto Produtivo",
            icon: "la la-thumbs-down",
            isBlocked: this.bloqueio.despesa_operacional != "t",
            status: this.status.despesa_operacional,
            to: {
              name: "pip_despesa_operacional_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          resumo_despesa_operacional: {
            titulo: "Resumo das Despesas Operacionais",
            icon: "la la-thumbs-down",
            isBlocked: this.bloqueio.resumo_despesa_operacional != "t",
            status: this.status.resumo_despesa_operacional,
            to: {
              name: "pip_resumo_despesa_operacional_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          custo_producao: {
            titulo: "Custos de Produção",
            icon: "la la-donate",
            isBlocked: this.bloqueio.custo_producao != "t",
            status: this.status.custo_producao,
            to: {
              name: "pip_custo_producao_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          resumo_custo_producao: {
            titulo: "Resumo dos Custos de Produção",
            icon: "la la-donate",
            isBlocked: this.bloqueio.resumo_custo_producao != "t",
            status: this.status.resumo_custo_producao,
            to: {
              name: "pip_resumo_custo_producao_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          fluxo_caixa: {
            titulo: "Indicadores de Viabilidade Econômico-Financeira",
            icon: "la la-tachometer-alt",
            isBlocked: this.bloqueio.fluxo_caixa != "t",
            status: this.status.fluxo_caixa,
            to: {
              name: "pip_fluxo_caixa_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          }
        },
        PN: {
          dados_gerais: {
            titulo: "Dados Gerais",
            icon: "la la-file-alt",
            isBlocked: this.bloqueio.dados_gerais != "t",
            status: this.status.dados_gerais,
            to: {
              name: "pip_dados_gerais_form",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          produtos: {
            titulo: "Produtos",
            icon: "la la-apple-alt",
            isBlocked: this.bloqueio.produto != "t",
            status: this.status.produto,
            to: {
              name: "pip_produto_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          insumos: {
            titulo: "Materiais",
            icon: "la la-shopping-cart",
            isBlocked: this.bloqueio.produto_insumo != "t",
            status: this.status.produto_insumo,
            to: {
              name: "pip_insumo_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          potencial_risco: {
            titulo: "Avaliação de Risco",
            icon: "la la-tasks",
            isBlocked: this.bloqueio.potencial_risco != "t",
            status: this.status.potencial_risco,
            to: {
              name: "pip_potencial_risco_form",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          investimentos: {
            titulo: "Investimento Total",
            icon: "la la-dollar-sign",
            isBlocked: this.bloqueio.investimento != "t",
            status: this.status.investimento,
            to: {
              name: "pip_investimento_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          depreciacao: {
            titulo: "Depreciação",
            icon: "la la-dollar-sign",
            isBlocked: this.bloqueio.investimento_depreciacao != "t",
            status: this.status.investimento_depreciacao,
            to: {
              name: "pip_depreciacao_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          resumo_investimento: {
            titulo: "Resumo dos Investimentos",
            icon: "la la-dollar-sign",
            isBlocked: this.bloqueio.resumo_investimento != "t",
            status: this.status.resumo_investimento,
            to: {
              name: "pip_resumo_investimento_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          plano_vendas: {
            titulo: "Plano de Vendas",
            icon: "la la-calendar",
            isBlocked: this.bloqueio.plano_vendas != "t",
            status: this.status.plano_vendas,
            to: {
              name: "pip_plano_vendas_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          preco_vendas: {
            titulo: "Preço de Venda",
            icon: "la la-money-bill-wave",
            isBlocked: this.bloqueio.preco_venda != "t",
            status: this.status.preco_venda,
            to: {
              name: "pip_preco_vendas_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          plano_producao: {
            titulo: "Plano de Produção",
            icon: "la la-file-invoice",
            isBlocked: this.bloqueio.plano_producao != "t",
            status: this.status.plano_producao,
            to: {
              name: "pip_plano_producao_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          mao_de_obra: {
            titulo: "Mão de Obra",
            icon: "la la-handshake",
            isBlocked: this.bloqueio.mao_de_obra != "t",
            status: this.status.mao_de_obra,
            to: {
              name: "pip_mao_de_obra_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          encargo: {
            titulo: "Encargos Sociais e Trabalhistas",
            icon: "la la-percentage",
            isBlocked: this.bloqueio.mao_de_obra_encargo != "t",
            status: this.status.mao_de_obra_encargo,
            to: {
              name: "pip_encargo_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          resumo_mao_de_obra: {
            titulo: "Resumo Mão de Obra",
            icon: "la la-handshake",
            isBlocked: this.bloqueio.resumo_mao_de_obra != "t",
            status: this.status.resumo_mao_de_obra,
            to: {
              name: "pip_resumo_mao_de_obra_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          despesa_operacional: {
            titulo: "Despesas Operacionais",
            icon: "la la-thumbs-down",
            isBlocked: this.bloqueio.despesa_operacional != "t",
            status: this.status.despesa_operacional,
            to: {
              name: "pip_despesa_operacional_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          resumo_despesa_operacional: {
            titulo: "Resumo Despesas Operacionais",
            icon: "la la-thumbs-down",
            isBlocked: this.bloqueio.resumo_despesa_operacional != "t",
            status: this.status.resumo_despesa_operacional,
            to: {
              name: "pip_resumo_despesa_operacional_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          custo_materiais: {
            titulo: "Custo com Materiais",
            icon: "la la-cart-plus",
            isBlocked: this.bloqueio.custo_materiais != "t",
            status: this.status.custo_materiais,
            to: {
              name: "pip_custo_materiais_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          dre: {
            titulo: "Demonstrativo de Resultados do Exercício - DRE",
            icon: "la la-file-invoice-dollar",
            isBlocked: this.bloqueio.dre != "t",
            status: this.status.dre,
            to: {
              name: "pip_dre_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          capital_giro: {
            titulo: "Capital de Giro",
            icon: "la la-cash-register",
            isBlocked: this.bloqueio.capital_de_giro != "t",
            status: this.status.capital_de_giro,
            to: {
              name: "pip_capital_giro_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          ponto_equilibrio: {
            titulo: "Ponto de Equilíbrio",
            icon: "la la-random",
            isBlocked: this.bloqueio.ponto_equilibrio != "t",
            status: this.status.ponto_equilibrio,
            to: {
              name: "pip_ponto_equilibrio_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          },
          fluxo_caixa: {
            titulo: "Indicadores de Viabilidade Econômico-Financeira",
            icon: "la la-tachometer-alt",
            isBlocked: this.bloqueio.fluxo_caixa != "t",
            status: this.status.fluxo_caixa,
            to: {
              name: "pip_fluxo_caixa_list",
              params: {
                idplano_investimento: this.idplano_investimento
              }
            }
          }
        }
      };
      return sections[this.tipo];
    },
    manual() {
      return {
        session: "pip_list",
        tipo: this.form.tipo_plano_investimento
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idplano_investimento)];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].plano ?? {};
        this.finalizado = res[R_GETDATA].finalizado;
        this.data_finalizado = res[R_GETDATA].data_finalizado;
        this.bloqueio = res[R_GETDATA].bloqueio ?? {};
        this.status = res[R_GETDATA].status ?? {};

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    async onSubmit() {
      const result = await MsgService.confirm(
        {
          title: "Confirmação de finalização".toUpperCase(),
          text: "Tem certeza que deseja prosseguir com a finalização? Depois de finalizado não poderá ser desfeito."
        },
        {
          showConfirmFeedback: false,
          showDenyFeedback: false
        }
      );

      if (result.isConfirmed) {
        this.ApiSubmitUpdate(
          `${this.idplano_investimento}/finalizar`,
          {},
          "refresh"
        );
      }
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
